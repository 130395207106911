import React from 'react';

import Layout from 'components/Layout';
import TabPanel from 'components/TabPanel';


const Academics = () => (
    <Layout pageMeta={{
        title: "Academic Readiness",
    }}>
        <main className="page programmes columns is-multiline is-gapless">
            <section className="page-header container">
                <div className="column is-12">
                    <h3 className="subtitle is-4">
                        <span>Programmes</span>
                    </h3>
                    <h2 className="title is-2">
                        <span>Academic Readiness</span>
                        <br /><sub>Ages 7 - 18</sub>
                    </h2>
                    <p>
                        Support your children formal education to let them achieve their full potentialw
                    </p>
                </div>
            </section>

            <div className="column is-12">
                <div className="container columns is-centered">
                    <div className="column is-10">
                        <TabPanel
                            level={1}
                            menu={[
                                {
                                    text: 'Future Scientist',
                                    icon: 'flask'
                                },
                                {
                                    text: 'School Success',
                                    icon: 'graduation-cap'
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="cta column is-12 has-text-centered">
                <a
                    href="https://wa.me/628164246780?text=Saya%20mau%20mendaftar%20untuk%20Academic%20Readiness%20Programme%20Studycle"
                    target="_blank" rel="noopener noreferrer"
                    className="button is-primary">Enrol Now</a>
            </div>
        </main>
    </Layout>
)


export default Academics;
