import React, { Component } from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

import Layout from 'components/Layout';
import LoginForm from 'components/forms/LoginForm';


// if (this.props.isAuthenticated) {
//     return <Redirect to="/" />
//   }

class LoginView extends Component {
  render() {
    return (

    <Layout pageMeta={{
        title: "Login",
    }}>
        <section className="login container columns is-centered">
            <div className="column is-8-tablet is-7-desktop is-6-widescreen">
                <LoginForm />
            </div>
        </section>
    </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
}

//export default connect(mapStateToProps, null)(LoginView);
export default LoginView;