import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import { Router } from 'react-router-dom';
// import { Provider } from "react-redux";
// import store from "./redux/store";

import App from './App';
import * as serviceWorker from './serviceWorker';

import ScrollToTop from 'components/ScrollToTop';

import './i18n';

// const prod = {
//     url: {
//      API_URL: 'https://myapp.herokuapp.com',
//      API_URL_USERS: 'https://myapp.herokuapp.com/users'}
//    };
//    const dev = {
//     url: {
//      API_URL: 'http://localhost:3000'
//     }
// };
// export const config = process.env.NODE_ENV === 'development' ? dev : prod;


// if ( process.env.NODE_ENV === 'development') {
//     middleware.push(logger);
//     enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
// }

const history = createBrowserHistory();

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev
} else {
    const trackingId = "UA-167195972-1"; // Replace with your Google Analytics tracking ID
    ReactGA.initialize(trackingId);

    history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
}


ReactDOM.render((
    <Router history={history}>
        {/* <Provider store={store}> */}
        {/*<I18nextProvider i18n={i18next}>*/}
        <Suspense fallback={null}>
            <ScrollToTop />
            <App />
        </Suspense>
        {/*</I18nextProvider>*/}
        {/* </Provider> */}
    </Router>), 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
