import React from 'react';
import DOMPurify from 'dompurify';

import PageService from 'services/api/page';
import Layout from 'components/Layout';
import PageNotFoundComponent from 'components/PageNotFound';

import ArticleComponent from './Article';
import LocationMap from './LocationMap';

class SingleArticlePage extends React.Component {
	state = {
		title: "",
		subtitle: "",
		article: "",
		locations: [],
		isFound: true,
	}

    fetchPageData() {
        const targetSlug = this.props.match.params.slug;

        // category: this.props.match.params.page_category
        PageService.get(targetSlug).then(
            (response) => {
                const contentItem = response;

                let coverImage = "";
                if (contentItem.image) {
                    coverImage = contentItem.image.url;
                }

                let contentBody = ""
                let locations = [];
                contentItem.body.forEach((data, i) => {
                    switch (data.type) {
                        case 'heading':
                            contentBody +=  `<h3 class="title is-4">${data.value}</h2>\n`;
                            break;
                        case 'image':
                            const imgData = data.value;
                            contentBody +=  `<img src="${imgData.file}" alt="${imgData.title}" />\n`;
                            break;
                        case 'location':
//                            locations.push({
//                                name: data.value.heading || '',
//                                address: data.value.address || '',
//                                lat: data.value.lat || null,
//                                lng: data.value.lng || null,
//                            })
                            locations.push(data.value);
                            contentBody += `<section></section>`;
                            break;
                        default:
                            contentBody +=  `<section class="page-content">${data.value}</section>\n`;
                            break;
                    }

                })

                this.setState(
                    {
                        isFound: true,
                        title: contentItem.title,
                        cover: coverImage,
                        subtitle: contentItem.subtitle,
                        article: contentBody,
                        locations,
                    }
                )
            }
        ).catch(error => {
            this.setState({
                isFound: false
            })
        })
    }

	componentDidMount() {
	    this.fetchPageData();
	}

	componentDidUpdate(prevProps) {
		const currentLocation = this.props.location.pathname.toLowerCase();
		const prevLocation = prevProps.location.pathname.toLowerCase();

		if (currentLocation !== prevLocation) {
		    this.fetchPageData();
		}
	}

	render() {
		const { title, subtitle, article, locations, isFound } = this.state;
		//const pageCategory = this.props.match.params.page_category;

		let htmlTitle = title;

		if (this.props.match.params.slug !== undefined) {

		}

        if (!isFound) {
            return <PageNotFoundComponent />
        }
		return (
			<Layout pageMeta={{
                title: htmlTitle,
            }}>
				<main className="page">
					<ArticleComponent
						title={title}
						subtitle={subtitle}>
						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article) }}>
						</div>
						{
                            locations &&
                            locations.length > 0 &&
                            <div className="columns is-multiline is-centered">
                             {
                                locations.map((location, idx) =>
                                    <LocationMap
                                        location={location}
                                        key={idx}
                                        width={6}
                                    />
                                )
                            }
                            </div>
                        }
					</ArticleComponent>
				</main>
			</Layout>
		)
	}
}

export default SingleArticlePage;
