import React from 'react';

import AgeGroupMark from 'icons/AgeGroupMark';
import RatingStar from 'icons/RatingStar';


const VertCard = ({ data }) => {
    let ratings = []
    for (let i = 0; i < 5; i++) {
        ratings.push(<RatingStar key={i} highlighted={i < data.rating} />)
    }

    let ageGroups = []
    if (data.ageGroup !== undefined) {
        for (let i = 0; i < data.ageGroup.length; i++) {
            ageGroups.push(<AgeGroupMark key={i} ageGroup={data.ageGroup[i]} />)
        }
    }

    return (
        <div className="card vert-card">
            <div className="card-image">
                <figure className="image is-5by3">
                    <img src={ data.cover } alt={ data.title } />
                </figure>
            </div>
            <div className="card-content">
                <p className="subtitle is-6">{ data.cat }</p>
                <p className="title is-5">{ data.title }</p>
            </div>
            <div className="card-footer columns is-multiline is-gapless is-mobile">
                <div className="column is-12">Available for: </div>
                <div className="column is-12">
                    { ageGroups }
                </div>
                {/* { data.type } */}
                {/* { ratings } */}


                {/* <span className="icon">
                    <i className="fas fa-arrow-alt-circle-right" />
                </span>
                <button className="button is-circle is-primary">
                    <i className="fas fa-arrow-right" />
                </button>    */}
            </div>
        </div>
    );
};

export default VertCard;