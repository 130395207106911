import React, { useEffect, useState, useRef } from "react";
import DOMPurify from 'dompurify';

import Chevron from "icons/Chevron.js"

const Accordion = (props) => {
//    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon");

    const content = useRef(null);

//    function toggleAccordion() {
//        setActiveState(setActive === "" ? "active" : "");
//
//        setHeightState(
//            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
//        );
//        setRotateState(
//            setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
//        );
//    }

    useEffect(() => {
        setHeightState(
            props.setActive === "active" ? `${content.current.scrollHeight}px` : "0px"
        );
        setRotateState(
            props.setActive === "active" ? "accordion__icon rotate" : "accordion__icon"
        );
    }, [props.setActive]);

    return (
        <div className="accordion__section">
            <button data-id={props.id} className={`accordion ${props.setActive}`} onClick={props.toggle}>
                <p className="accordion__title">{props.title}</p>
                <Chevron className={`${setRotate}`} width={10} fill={"#444"} />
            </button>
            <div
                ref={content}
                style={{ maxHeight: `${setHeight}` }}
                className="accordion__content"
            >
                <div
                    className="accordion__text"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.content) }}
                />
            </div>
        </div>
    );
}

export default Accordion;