import request from 'helpers/network/request'

function get(slug) {
    return request({
        //url: `pages/?type=pages.GeneralPage&fields=*&slug=${slug}`,
        url: `pages/${slug}/`,
        method: 'GET'
    });
}

function create({subject, content}) {
    return request({
        url:    'pages',
        method: 'POST',
        data:   {
            subject,
            content
        }
    });
}

const PageService = {
    get, create //, update, delete, etc. ...
}

export default PageService;