import React from 'react';


const RatingStar = ({ highlighted }) => {
    return (
        <span
            className="icon"
            style={
                highlighted ? { color: '#FFBB38' } : { color: '#CECECE' }
        }>
            <i className="fas fa-star" />
        </span>
    )
}

export default RatingStar;