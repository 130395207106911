import React, { useState } from "react";
import { Link } from 'react-router-dom';
import VertCard from 'components/cards/VertCard';

import { courseData } from 'helpers/courses';


const RecommendedCourses = (props) => {
    const trackCourses = [
        [
            [1, 2, 5],
            [2, 5],
            [1, 2],
        ],
        [
            [1, 2, 5],
            [2, 3, 4],
        ],
        [
            [3],
            [3],
            [],
        ]
    ]

    return (

        <section className="columns is-multiline">
        {
            ((trackCourses[props.level][props.track].length === 0) &&
            (
                <div className="column is-12-mobile has-text-centered">
                    <p className="is-size-4">Coming Soon</p>
                </div>
            )
            )
            ||
            <div className="column is-12 has-text-centered">
                <p className="title is-4">Recommended Courses</p>
            </div>
        }
        {
            trackCourses[props.level][props.track].length > 0 &&
            Object.entries(courseData).map(([key, data], idx) => {
                if (trackCourses[props.level][props.track].includes(data.id))
                    return (
                        <div className="column is-12-mobile is-4-tablet scl-vertcard-container" key={idx}>
                            <Link
                                to={{
                                    pathname: data.url,
                                    state: {
                                        course: data
                                    }
                                }}
                            >
                                <VertCard
                                    data={{
                                        title: data.title,
                                        cover: data.cover,
                                        cat: data.cat,
                                        ageGroup: data.ageGroup,
                                        method: "offline",
                                        rating: 4,
                                        price: 0,
                                    }}
                                />
                            </Link>
                        </div>
                    )

                else return (null)
            })

        }
        </section>
    )
}

const TabHeaderItem = (props) => (
    <li data-id={props.id} className={`${props.isActive}`} onClick={props.toggle}>
        <a>
            <span className="icon is-small"><i className={"fas fa-" + props.icon} aria-hidden="true"></i></span>
            <span>{props.text}</span>
        </a>
    </li>
);

const TabPanel = (props) => {
    const initialSelectedItem = parseInt(props.selectedItem) >= 0 ? parseInt(props.selectedItem) : 0;
    const [selectedItem, setSelectedItem] = useState(initialSelectedItem);

    function togglePanel(e) {
        const newSelectedItem = parseInt(e.currentTarget.dataset.id)
        if (newSelectedItem !== selectedItem)
            setSelectedItem(parseInt(e.currentTarget.dataset.id))
    }

    return (
        <div className="tabs-container">
            <div className="tabs is-toggle is-fullwidth">
                <ul>
                    {
                        props.menu.map((value, idx) =>
                            <TabHeaderItem
                                text={value.text}
                                icon={value.icon}
                                isActive={idx === selectedItem ? "is-active" : ""}
                                key={idx}
                                id={idx}
                                toggle={togglePanel}
                             />
                        )
                    }
                </ul>
            </div>

            <div>
                {
                    <RecommendedCourses
                        level={props.level}
                        track={selectedItem}
                     />
                }
            </div>
        </div>
    );
}

export default TabPanel;