import React from 'react';

import Layout from 'components/Layout';

const MembershipView = () => (
    <Layout pageMeta={{
        title: "Membership",
    }}>
        <main className="page columns is-multiline is-gapless">
            <section className="page-header container">
                <div className="column is-12">
                    <h3 className="subtitle is-4">
                        <span>Colearning Space</span>
                    </h3>
                    <h2 className="title is-2">
                        Our <span>Membership</span> Plans
                    </h2>
                </div>
            </section>

            <div className="column is-12">
                <section className="page-content container">
                    <div className="columns is-centered">
                        <div className="column is-3">
                            <div className="card pricing">
                                <div className="card-header">
                                    <p className="title">Lite</p>
                                </div>
                                <div className="card-content">
                                    <ul>
                                        <li>Enrolment for <span>1 regular course</span></li>
                                        <li><span>10 hours</span> on-demand access to colearning space</li>
                                        <li><span>50 credits</span> for printing service</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="column is-3">
                            <div className="card pricing">
                                <div className="card-header">
                                    <p className="title">Regular</p>
                                </div>
                                <div className="card-content">
                                    <ul>
                                        <li>Enrolment for <span>2 regular courses</span></li>
                                        <li><span>20 hours</span> on-demand access to colearning space</li>
                                        <li><span>75 credits</span> for printing service </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="column is-3">
                            <div className="card pricing">
                                <div className="card-header">
                                    <p className="title">Intensive</p>
                                </div>
                                <div className="card-content">
                                    <ul>
                                        <li>Enrolment for <span>3 regular courses</span></li>
                                        <li><span>40 hours</span> on-demand access to colearning space</li>
                                        <li><span>100 credits</span> for printing service </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section className="page-header container">
                <div className="column is-12">
                    <h2 className="title is-2">
                        Membership <span>Benefits</span>
                    </h2>
                </div>
            </section>

            <div className="column is-12">
                <section className="page-content container">
                    <div className="member-benefits columns is-centered is-multiline">
                        <div className="column is-3">
                            <span className="icon"><i className="far fa-calendar-alt" /></span>
                            <p>Member-only Events</p>
                        </div>
                        <div className="column is-3">
                            <span className="icon"><i className="fas fa-robot" /></span>
                            <p>Exclusive Workshops</p>
                        </div>
                        <div className="column is-3">
                            <span className="icon"><i className="fas fa-wifi" /></span>
                            <p>High Speed Internet</p>
                        </div>
                        <div className="column is-3">
                            <span className="icon"><i className="fas fa-percentage" /></span>
                            <p>Discounts on Store Merchandise</p>
                        </div>
                        <div className="column is-3">
                            <span className="icon"><i className="fas fa-user-friends" /></span>
                            <p>Guest passes for friends</p>
                        </div>
                        <div className="column is-3">
                            <span className="icon"><i className="far fa-clock" /></span>
                            <p>7-day Access</p>
                        </div>
                        <div className="column is-3">
                            <span className="icon"><i className="fas fa-rocket" /></span>
                            <p>Academic or career concierge service</p>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </Layout>
)


export default MembershipView;
	