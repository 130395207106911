import React from 'react';

import Layout from 'components/Layout';
import TabPanel from 'components/TabPanel';


const CareerSkills = () => (
    <Layout pageMeta={{
        title: "Career Skills",
    }}>
        <main className="page programmes columns is-multiline is-gapless">
            <section className="page-header container">
                <div className="column is-12">
                    <h3 className="subtitle is-4">
                        <span>Programmes</span>
                    </h3>
                    <h2 className="title is-2">
                        <span>Career Skills</span>
                        <br /><sub>Ages 19 and above</sub>
                    </h2>
                    <p>
                        Follow your passion and get ready for global competition
                    </p>
                </div>
            </section>

            <div className="column is-12">
                <div className="container columns is-centered">
                    <div className="column is-10">
                        <TabPanel
                            level={2}
                            menu={[
                                {
                                    text: 'Techpreneur',
                                    icon: 'user-tie'
                                },
                                {
                                    text: 'Full Stack Developer',
                                    icon: 'laptop-code'
                                },
                                {
                                    text: 'Digital Marketer',
                                    icon: 'chart-line'

                                }
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="cta column is-12 has-text-centered">
                <a
                    href="https://wa.me/628164246780?text=Saya%20mau%20mendaftar%20untuk%20Career%20Skills%20Programme%20Studycle"
                    target="_blank" rel="noopener noreferrer"
                    className="button is-primary">Enrol Now</a>
            </div>
        </main>
    </Layout>
)


export default CareerSkills;
