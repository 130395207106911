import AboutUs from './AboutUs';
import ColearningExperience from './ColearningXp';
import Locations from './Locations';
import Membership from './Membership';


const Pages = {
    AboutUs,
    ColearningExperience,
    Locations,
    Membership,
}

export default Pages;