import React from 'react';

import Layout from 'components/Layout';
import LocationMap from 'components/pages/LocationMap';

import { useTranslation } from 'react-i18next';


const locations = [
    {
        name: 'Indonesia',
        lat: -6.282792,
        lng: 107.168677,
        address: '<p>Jababeka Education Park</p><p>Jl. Ki Hajar Dewantara No. 73, North Cikarang</p><p>Bekasi, 17530</p>',
    },
    // {
    //     name: 'Indonesia',
    //     lat: -6.251493,
    //     lng: 106.978711,
    //     address: '<p>Lagoon Avenue Mall</p><p>Ground Floor #G-56A</p><p>Jl. KH Noer Ali, Pekayon Jaya</p><p>Bekasi Selatan, 17148</p>',
    // },
    {
        name: 'Singapore',
        lat: 1.306919,
        lng: 103.904563,
        address: '<p>Katong Point</p><p>451 Joo Chiat Rd.</p><p>Singapore 427664</p>',
    }
]

const LocationsView = () => {
    const { t } = useTranslation('common');

    return (
        <Layout pageMeta={{
            title: t('locations', 'Locations'),
        }}>
            <main className="page columns is-multiline is-gapless">
                <section className="page-header container">
                    <div className="column is-12">
                        <h3 className="subtitle is-4">
                            <span>{ t('colearning_space', 'Colearning Space') }</span>
                        </h3>
                        <h2 className="title is-2">
                            Our <span>{ t('locations', 'Locations') }</span>
                        </h2>
                    </div>
                </section>

                <div className="column is-12 is-size-5 has-text-weight-normal">
                    <section className="section page-content container">
                        <div className="columns is-multiline is-centered">
                        {
                            locations &&
                            locations.map((location, idx) => (
                                <LocationMap
                                    location={location}
                                    key={idx}
                                    width={6} />
                            ))
                        }
                        </div>
                    </section>
                </div>
            </main>
        </Layout>
    );
};


export default LocationsView;
