import React from 'react';
import { Route, Switch } from 'react-router-dom';

import 'styles/studycle.scss';

import HomePageView from 'views/HomePageView';
import CourseList from 'views/courses/CourseList';
import SingleCourseView from 'views/courses/SingleCourseView';

import PageNotFoundComponent from 'components/PageNotFound';

import ColearningPages from 'views/space';
import * as ProgrammePages from 'views/programmes';
import Login from 'views/Login';

import SingleArticlePage from 'components/pages/SingleArticlePage';



function App() {
    return (
        <Switch>
            <Route exact path="/" component={ HomePageView } />
            <Route path="/our-space/colearning-experience" component={ ColearningPages.ColearningExperience } />
            <Route path="/our-space/locations" component={ ColearningPages.Locations } />
            <Route path="/our-space/membership" component={ ColearningPages.Membership } />
            <Route path="/our-space/about-us" component={ ColearningPages.AboutUs } />

            <Route path="/programmes/early-years-enrichment" component={ ProgrammePages.EarlyYears } />
            <Route path="/programmes/academic-readiness" component={ ProgrammePages.Academics } />
            <Route path="/programmes/career-skills" component={ ProgrammePages.CareerSkills } />

            <Route path="/courses/:slug" component={ SingleCourseView } />
            <Route path="/courses" component={ CourseList } />

            <Route path="/login" component={ Login } />

            <Route path="/:page_category/:slug" component={ SingleArticlePage } />

            <Route component={ PageNotFoundComponent }/>
        </Switch>
    );
}

export default App;
