import React from 'react';
import { Link } from 'react-router-dom';

import SectionWrapper from './Wrapper';


const Programmes = ({ title, children }) => {
    return (
        <SectionWrapper
            className="is-scl-green-light"
            title="Our Programmes"
            isTitleCentered={true}
            isFullwidth={true}
            id="programmes"
        >
            <div className="column is-12 has-text-centered">
                <div className="subtitle">
                    Each of our programme is designed to empower you with foundation to push forward.
                </div>
            </div>
            <div className="column is-12 columns is-multiline is-centered"
                style={{
                    //marginTop: "-5rem",
                    marginTop: "2rem",
                    padding: "0 3rem",
                }}>
                <div className="column is-6-tablet is-4-desktop">
                    <Link to="/programmes/early-years-enrichment">
                    <div className="card featured-card scl-early-years">
                        <div className="card-content">
                            <h3 className="title is-4">Early Years Enrichment</h3>
                            <span className="subtitle">Ages 3 - 6</span>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="column is-6-tablet is-4-desktop">
                    <Link to="/programmes/academic-readiness">
                    <div className="card featured-card scl-academic-readiness">
                        <div className="card-content">
                            <h3 className="title is-4">Academic Readiness</h3>
                            <span className="subtitle">Ages 7-18</span>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="column is-6-tablet is-4-desktop">
                    <Link to="/programmes/career-skills">
                    <div className="card featured-card scl-career-skills">
                        <div className="card-content">
                            <h3 className="title is-4">Career Skills</h3>
                            <span className="subtitle">Ages 19 and above</span>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
            {/* <div className="columns container is-multiline">
            <div className="column is-12 card banner-card is-scl-green">
                <div className="card-content has-text-centered">
                    <p className="subtitle">

                    </p>
                    ///<button className="button is-rounded is-primary is-light">Start Trial</button>
                </div>


            </div>
            </div>

        </div> */}
        </SectionWrapper>
    );
};

export default Programmes;