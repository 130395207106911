import React from 'react';


export const AgeGroupMark = ({ ageGroup }) => {
    let groupClass = "";
    let groupName = "";

    switch (ageGroup) {
        case 0:
            groupClass = " is-scl-early";
            groupName = "Early Years";
            break;
        case 1:
            groupClass = " is-scl-young";
            groupName = "Schoolers";
            break;
        case 2:
            groupClass = " is-scl-adult";
            groupName = "Adult";
            break;
        default:
            groupClass = " is-scl-young";
            break;
    }

    return (
        <div className="target-age">
            <span
                className={"icon" + groupClass}
            >
                <i className={"fas fa-square"} />
            </span>
            { groupName }
        </div>
    )
}

export default AgeGroupMark;