import React from 'react';
import { Link } from 'react-router-dom';

import Layout from 'components/Layout';
import VertCard from 'components/cards/VertCard';

import { courseData } from 'helpers/courses';



const SingleCourseView = (props) => {
    return (
    <Layout pageMeta={{
        title: 'Courses',
    }}>
        <main className="page course columns is-multiline is-gapless">
            <section className="page-header container">
                <div className="column is-12">
                    <h3 className="subtitle is-4">
                        <span>Course & Workshops</span>
                    </h3>
                    <h2 className="title is-2">
                        Our <span>Courses</span>
                    </h2>
                </div>
            </section>

            <div className="container columns is-multiline is-centered">
                <div className="column is-12-desktop is-10-widescreen columns is-multiline">
                {
                Object.entries(courseData).map(([key, data], idx) => {
                return (
                    <div className="column is-12-mobile is-4-tablet scl-vertcard-container" key={idx}>
                        <Link
                            to={{
                                pathname: data.url,
                                state: {
                                    course: data
                                }
                            }}
                        >
                            <VertCard
                                data={{
                                    title: data.title,
                                    cover: data.cover,
                                    cat: data.cat,
                                    ageGroup: data.ageGroup,
                                    method: "offline",
                                    rating: 4,
                                    price: 0,
                                }}
                            />
                        </Link>
                    </div>
                )
                })}
                </div>
            </div>
        </main>
    </Layout>)
}


export default SingleCourseView;
