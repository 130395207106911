import React from 'react';

import Layout from 'components/Layout';
import TabPanel from 'components/TabPanel';


const EarlyYears = () => (
    <Layout pageMeta={{
        title: "Early Years Enrichment",
    }}>
        <main className="page programmes columns is-multiline is-gapless">
            <section className="page-header container">
                <div className="column is-12">
                    <h3 className="subtitle is-4">
                        <span>Programmes</span>
                    </h3>
                    <h2 className="title is-2">
                        <span>Early Years Enrichment</span>
                        <br /><sub>Ages 3 - 6</sub>
                    </h2>
                    <p>
                        Three amazing tracks based on your child’s interests
                    </p>
                </div>
            </section>

            <div className="column is-12">
                <div className="container columns is-centered">
                    <div className="column is-10">
                        <TabPanel
                            level={0}
                            menu={[
                                {
                                    text: 'Junior Engineer',
                                    icon: 'hard-hat'
                                },
                                {
                                    text: 'Little Artist',
                                    icon: 'palette'
                                },
                                {
                                    text: 'Mini Explorer',
                                    icon: 'search'

                                }
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="cta column is-12 has-text-centered">
                <a
                    href="https://wa.me/628164246780?text=Saya%20mau%20mendaftar%20untuk%20Early%20Years%20Enrichment%20Programme%20Studycle"
                    target="_blank" rel="noopener noreferrer"
                    className="button is-primary">Enrol Now</a>
            </div>
        </main>
    </Layout>
)


export default EarlyYears;
