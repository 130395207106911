import React from 'react';

import { Link } from "react-scroll";
import Layout from '../components/Layout'
import * as Sections from 'components/sections';
//import ColearningPages from 'views/space';
import { withTranslation } from 'react-i18next';


class HomePageView extends React.Component {
    render() {
        return (
            <Layout pageMeta={{
                title: "Cultivate curiosity to learn",
            }}>
                <main>
                    <section className="hero is-large container">
                        <div className="hero-main hero-body">
                            {
                            /* <div className="mask-1 is-overlay">
                                <figure className="image">
                                    <img src="/static/img/hero-mask-1.svg" alt="hero_mask" />
                                </figure>
                            </div> */
                            }
                            <div className="hero-text is-flex is-overlay single-spaced">
                                <h1 className="title has-text-white is-size-4-mobile is-size-3">
                                    { this.props.t('home.banner_title', 'Ignite Curiosity, Learn Together') }
                                </h1>
                                <h2 className="subtitle has-text-white is-size-6-mobile is-size-5">
                                    { this.props.t('home.banner_text',
                                        `From early years enrichment classes to future-ready skill based courses,
                                         our colearning space offers flexible learning programmes to empower your
                                         potentials for global competitions.
                                        `)
                                    }
                                </h2>
                                <div className="buttons is-centered">
                                    <Link
                                        className="button is-primary"
                                        to="programmes"
                                        smooth={true}
                                        offset={0}
                                        duration={500}
                                    >
                                    {
                                        this.props.t('home.button_programmes', 'View Programmes')
                                    }
                                    </Link>
                                    <a
                                        href="https://wa.me/628164246780?text=Saya%20tertarik%20untuk%20mencoba%20trial%20session%20Studycle"
                                        target="_blank" rel="noopener noreferrer"
                                        className="button is-light">{
                                        this.props.t('home.button_trial', 'Schedule Trial Session')
                                        }
                                    </a>
                                </div>


                            </div>
                        </div>
                    </section>


                    <Sections.Intro />
                    <Sections.Programmes />
                    <Sections.Courses />

                    {/*
                    <Sections.Enterprise />
                    <Sections.Store />
                    <Sections.Resources />
                    */}

                    {/*
                    <section id="solutions" className="section container">
                        <h1 className="title has-text-centered">Solutions</h1>
                        <div className="columns">
                            <div className="column is-4">
                                <div className="card">
                                    <div className="card-image">
                                        <figure className="image is-4by3">
                                            <img src={solutions_explorer} alt="School Explorer" />
                                        </figure>
                                    </div>
                                    <div className="card-content has-text-centered">
                                        <div className="media">
                                        <div className="media-content">
                                            <p className="title is-4">School Explorer</p>
                                        </div>
                                        </div>
                                        <div className="content">
                                            Explore 500+ education programmes and scholarships.
                                            Finding your dream schools has never been easier - it's free!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="card">
                                    <div className="card-image">
                                        <figure className="image is-4by3">
                                            <img src={solutions_learning} alt="Adaptive Learning Portal" />
                                        </figure>
                                    </div>
                                    <div className="card-content has-text-centered">
                                        <div className="media">
                                        <div className="media-content">
                                            <p className="title is-4">Adaptive Learning Portal</p>
                                        </div>
                                        </div>

                                        <div className="content">
                                            Progress at your own pace, learning becomes more flexible and convenient.
                                            It gives you an effective and personalized learning journey.
                                        </div>
                                    </div>
                                    </div>
                            </div>
                            <div className="column is-4">
                            <div className="card">
                                <div className="card-image">
                                    <figure className="image is-4by3">
                                        <img src={solutions_dashboard} alt="Course Management System" />
                                    </figure>
                                </div>
                                <div className="card-content has-text-centered">
                                    <div className="media">
                                    <div className="media-content">
                                        <p className="title is-4">Course Management System</p>
                                    </div>
                                    </div>

                                    <div className="content">
                                        Empower your institution with an easy-to-use course management system.
                                        Manage courses and track students' progress without any hassle.
                                    </div>
                                </div>
                                </div>
                        </div>
                        </div>
                    </section> */}
                </main>
            </Layout>
        );
    }
  }

export default withTranslation('pages')(HomePageView);
