import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


class Header extends React.Component {
    state = {
        isActive: false,
    }

    toggleNav = () => {
        this.setState(prevState => ({
            isActive: !prevState.isActive
        }))
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname)
        {
            this.setState({
                isActive: false,
            })
        }
    }

    render() {
        return (
        <header>
            <nav className="navbar container">
            <div className="navbar-brand">
                <a className="navbar-item" href="/">
                    <img src="/static/img/logo/studycle.png" alt="Studycle Logo"/>
                </a>
                
                <div className={ this.state.isActive ? 
                    'navbar-burger burger is-active' : 
                    'navbar-burger burger'} data-target="main-navbar" 
                    onClick={this.toggleNav}>
                <span></span>
                <span></span>
                <span></span>
                </div>
            </div>
            <div id="main-navbar" 
                className={ this.state.isActive ? 
                    "navbar-menu is-active" :
                    "navbar-menu" }>
                <div className="navbar-start">
                    {
//                    <NavLink className="navbar-item"
//                        activeClassName="is-active"
//                        exact
//                        to='/'>{ this.props.t('home', 'Home') }</NavLink>
                    }
                    <div className="navbar-item has-dropdown is-hoverable">
                        <NavLink 
                            to='/our-space/'
                            className="navbar-link is-arrowless"
                            activeClassName="is-active"
                            onClick={(e) => { e.preventDefault(); }}
                        >
                            { this.props.t('colearning_space', 'Colearning Space') }
                        </NavLink>
                        <div className="navbar-dropdown" >
                            <NavLink className="navbar-item" to='/our-space/colearning-experience'>
                                { this.props.t('colearning_experience', 'Colearning Experience') }
                            </NavLink>
                            <NavLink className="navbar-item" to='/our-space/membership'>
                                { this.props.t('membership', 'Membership') }
                            </NavLink>
                            <NavLink className="navbar-item" to='/our-space/locations'>
                                { this.props.t('locations', 'Locations') }
                            </NavLink>
                            <NavLink className="navbar-item" to='/our-space/about-us'>
                                { this.props.t('about_us', 'About Us') }
                            </NavLink>
                        </div>
                    </div>
                    <div className="navbar-item has-dropdown is-hoverable">
                        <NavLink 
                            to='/programmes/' 
                            className="navbar-link is-arrowless"
                            activeClassName="is-active"
                            onClick={(e) => { e.preventDefault(); }}
                        >
                            { this.props.t('programs', 'Programmes') }
                        </NavLink>
                        {/* <span className="line"
                            style={{
                                backgroundColor: "#ebebec",
                                position: "absolute",
                                width: "100%",
                                height: "3px",
                                bottom: "-2px",
                                left: "0"
                            }}></span> */}

                        <div className="navbar-dropdown">
                            <NavLink className="navbar-item" to='/programmes/early-years-enrichment'>
                                { this.props.t('early_years', 'Early Years Enrichment (Ages 3 - 6)') }
                            </NavLink>
                            <NavLink className="navbar-item" to='/programmes/academic-readiness'>
                                { this.props.t('academics', 'Academic Readiness (Ages 7 - 18)') }
                            </NavLink>
                            <NavLink className="navbar-item" to='/programmes/career-skills'>
                                { this.props.t('career', 'Career Skills (Ages 19 and above)') }
                            </NavLink>
                            <NavLink className="navbar-item" to='/courses/'>
                                { this.props.t('courses_workshops', 'Courses & Workshops') }
                            </NavLink>
                        </div>
                    </div>

                    <NavLink
                        to='/study-overseas/'
                        className="navbar-item"
                        activeClassName="is-active"
                    >
                        { this.props.t('study_overseas', 'Study Overseas') }
                    </NavLink>
                    {
                    /*
                    <div className="navbar-item has-dropdown is-hoverable">
                        <NavLink 
                            to='/study-overseas/'
                            className="navbar-link is-arrowless"
                            activeClassName="is-active" 
                            onClick={(e) => { e.preventDefault(); }}
                        >
                            { this.props.t('study_overseas', 'Study Overseas') }
                        </NavLink>
                        <div className="navbar-dropdown">
                            <NavLink className="navbar-item" to='/study-abroad/schools'>
                                { this.props.t('discover_schools', 'Discover Schools') }
                            </NavLink>
                            <NavLink className="navbar-item" to='/study-abroad/scholarships'>
                                { this.props.t('scholarships', 'Scholarships') }
                            </NavLink>
                            <NavLink className="navbar-item" to='/study-abroad/tours'>
                                { this.props.t('study_tour', 'Study Tour') }
                            </NavLink>
                        </div>
                    </div>
                    */
                    }
                    <NavLink 
                        to='/resources/'
                        className="navbar-item"
                        activeClassName="is-active" 
                    >
                        { this.props.t('resources', 'Resources') }
                    </NavLink>
                    {/* <NavLink className="navbar-item" 
                        activeClassName="is-active" to='/store/'>Store</NavLink> */}
                </div>
                <div className="navbar-end has-text-centered">
                    <a
                        href="https://wa.me/628164246780?text=Saya%20mau%20bertanya%20tentang%20Studycle"
                        target="_blank" rel="noopener noreferrer"
                        className="navbar-item">Contact Us</a>
                    <div className="navbar-item">
                        <NavLink 
                            to='/login'
                            className="button is-primary"
                            activeClassName="is-active"
                        >
                            <span className="icon"><i className="fas fa-user" /></span>
                            <span>Login</span>
                        </NavLink>
                    </div>
                </div>
            </div>
            </nav>
        </header>
      );
    }
  }

export default withRouter(withTranslation('common')(Header));
