import React from "react"
import { Helmet } from 'react-helmet';

import Header from './Header';
import Footer from './Footer';


export default ({ pageMeta, children }) => (
    <>
        <Helmet>
            <title>{`Studycle | ${pageMeta.title}`}</title>
            {/* <title>Studycle | Cultivate curiosity to learn</title> */}
            {
               pageMeta && pageMeta.description &&
                <meta name="description" content={pageMeta.description} />
            }
            {/* We pass an array of keywords, and then we use the Array.join method to convert them to a string where each keyword is separated by a comma */}
            {
                pageMeta && pageMeta.keywords &&
                <meta name="keywords" content={pageMeta.keywords.join(',')} />
            }
        </Helmet>
        <div className="App">
            <Header />
            {children}
            <Footer />
        </div>
    </>
)