import axios from 'axios'
//import constants from './constants'

let baseURL = "";
let isDev = false;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:8000/api';
}
else if (process.env.NODE_ENV === 'production') {
    baseURL = 'https://studycle.com/api';
    isDev = false;
}

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL,
  //baseURL: constants.url
  //auth: { Authorization: 'Bearer ' + {token} }
});

/**
 * Request Wrapper with default success/error actions
 */
const request = function(options) {
    const onSuccess = function(response) {
        if (isDev) console.debug('Request Successful!', response);
        return response.data;
    }

    const onError = function(error) {
        if (isDev) console.error('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx

            if (isDev) {
                console.error('Status:',  error.response.status);
                console.error('Data:',    error.response.data);
                console.error('Headers:', error.response.headers);

                console.log('Response: ', error.response);
                console.log('Request: ', error.request);
                console.log('Message: ', error.message);
            }
        } else {
            // Something else happened while setting up the request
            // triggered the error
            if (isDev) console.error('Error Message:', error.message);
        }

        // return Promise.reject(error.response || error.message);
        return Promise.reject(error);
    }

    return client(options)
        .then(onSuccess)
        .catch(onError);
}

export default request;