import React from 'react';
import { Link } from 'react-router-dom';

import SectionWrapper from './Wrapper';
import VertCard from 'components/cards/VertCard';

import { courseData } from 'helpers/courses';


const Courses = ({ title, children }) => {
    return (
        <SectionWrapper
            title="Our Popular Courses"
        >
            <div className="column is-12 columns is-gapless">
                <p className="column is-8">
                    <span className="subtitle">
                    Here are our favourite courses that you might want to learn from our expert educators.
                    </span>
                </p>
                <div className="column">
                    <Link to="/courses/" className="button is-rounded is-primary is-light is-pulled-right">
                        <span className="icon is-small"><i className="fas fa-plus-circle"></i></span>
                        <span>Browse all</span>
                    </Link>
                </div>
            </div>

        { Object.entries(courseData).map(([key, data], idx) => {
            return (
                <div className="column is-12-mobile is-4-tablet scl-vertcard-container" key={idx}>
                    <Link
                        to={{
                            pathname: data.url,
                            state: {
                                course: data
                            }
                        }}
                    >
                        <VertCard
                            data={{
                                title: data.title,
                                cover: data.cover,
                                cat: data.cat,
                                ageGroup: data.ageGroup,
                                method: "offline",
                                rating: 4,
                                price: 0,
                            }}
                        />
                    </Link>
                </div>
            )
        }) }
        </SectionWrapper>
    );
};

export default Courses;