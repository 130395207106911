import React from 'react';

export const SectionWrapper = ({
        title = null,
        isTitleCentered = false,
        isFullwidth = false,
        className = null,
        style = null,
        id = null,
        children
    }) => {
    const defaultClassName = "columns is-multiline is-mobile";
    const sectionClassName =
        (isFullwidth ? "section" : "section container") +
        ((className !== null) ?  " " + className : "");

    const titleClassName = isTitleCentered ? "title has-text-centered is-2" : "title is-2";
    const sectionStyle = style ? style : {};

    return (
        <section className={sectionClassName} style={sectionStyle} id={id}>
            {
                title
                && (<h2 className={ titleClassName }>{ title }</h2>)
            }
            <div className={ defaultClassName }>
                { children }
            </div>
        </section>
    );
};

export default SectionWrapper;