import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import XHRBackend from 'i18next-xhr-backend';

i18n
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(XHRBackend)
    // connect with React
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //debug: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? true : false,
        debug: false,

        lng: 'en',
        fallbackLng: 'en',
        whitelist: ['en', 'id'],

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },

        // have a common namespace used around the full app
        //    ns: ["translations"],
        //    defaultNS: "translations"
        defaultNS: "common"
    });

export default i18n;