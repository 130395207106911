import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Layout from 'components/Layout';

const ColearningExperienceView = () => {
    const { t } = useTranslation(['common', 'pages']);

    return (
    <Layout pageMeta={{
        title: t('colearning_experience', "Colearning Experience"),
    }}>
        <main className="page columns is-multiline is-gapless">
            <div className="column is-12 is-size-5 has-text-weight-normal">
                <section className="section page-content container">
                    <div className="columns is-vcentered">
                        <div className="column is-6">
                            <h3 className="subtitle is-4">
                                <span>{ t('colearning_space', "Colearning Space") }</span>
                            </h3>
                            <h2 className="title is-2">
                                { t('pages:colearning.desc_title', "What is") } <span><i>{ t('colearning', "Colearning") }</i></span>?
                            </h2>
                            <p className="quote is-size-4">
                                <Trans i18nKey="pages:colearning.desc">
                                    <i>Colearning</i> is about getting a small group of people learning together in
                                    the same space and allowing them to collaborate with one another in every part of
                                    the learning process.
                                </Trans>
                            </p>
                            <p>
                            { t('pages:colearning.desc_2', `There is no "teacher" who will be dominant in this
                            group of learners and make the rest to feel restricted, but there is a tutor who
                            will be a facilitator to encourage the collaboration and also guide learners throughout all collaborative
                            learning activities.`) }
                            </p>
                        </div>

                        <figure className="column image">
                            <img src="/static/img/pages/colearning.jpg" alt="Colearning Space" />
                        </figure>
                    </div>
                </section>


                <blockquote className="blockquote columns is-yellow is-gapless">
                    <div className="column is-12">
                        <i className="fas fa-2x fa-quote-left is-size-5" />
                    </div>
                    <div className="column is-10 is-size-3">
                        { t('pages:colearning.quote', "Colearning creates a positive and vibrant learning environment so everyone can learn more!") }
                    </div>
                </blockquote>

                <section className="section page-content container">
                    <div className="columns is-vcentered">

                        <figure className="column image">
                            <img src="/static/img/pages/colearning-experience.jpg" alt="Colearning Experience" />
                        </figure>
                        <div className="column is-6">
                            <h2 className="title is-2">
                                Bringing <span>Colearning Experience</span> To You
                            </h2>
                            <p>
                                We offer colearning experience for everyone of all ages to push forward in their academic or
                                career life. Young children can prepare their foundation to learn and socialize, schoolers can
                                work on their studies and improve their skills of interest, adults can learn practical skills
                                to improve their future career, while entrepreneurs can also collaborate with others to invent
                                and create new opportunities.
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </Layout>
    )
}


export default ColearningExperienceView;
	