import React from 'react';


const ArticleComponent = ({
		title = "",
		subtitle = "",
		className = null,
		style = null,
		children
	}) => {
	const sectionClassName = "section columns is-multiline" //is-centered
		+  ((className !== null) ?  " " + className : "");

	const sectionStyle = style ? style : {};

	return (
		<section className={sectionClassName} style={sectionStyle}>
		    <div className="column is-8-tablet is-6-widescreen page-header">
		        <div className="subtitle is-4">
					{ subtitle }
				</div>
				<h2 className="title is-1">
					{ title }
				</h2>
		    </div>
			<div className="column is-12 is-size-5 has-text-weight-normal">
				{ children }
			</div>
		</section>
	);
};

export default ArticleComponent;