import React from 'react';
import { Link } from 'react-router-dom';


export const SocialMediaComponent = ({ title, children }) => {
    return (
		<ul className="social-media">
			<li>
				<a href="https://facebook.com/Studycle"
				    target="_blank" rel="noopener noreferrer"
				    className="blue">
					<span className="icon"><i className="fab fa-facebook-f" /></span>
				</a>
			</li>
			<li>
				<a href="https://instagram.com/Studycle.id"
				    target="_blank" rel="noopener noreferrer"
				    className="yellow">
					<span className="icon"><i className="fab fa-instagram" /></span>
				</a>
			</li>
		
			<li>
				<a href="https://www.youtube.com/channel/UClxGiVoh4XPZUG4bS3_a76A"
				    target="_blank" rel="noopener noreferrer"
				    className="red">
					<span className="icon"><i className="fab fa-youtube" /></span>
				</a>
			</li>
		
		</ul>
    );
};

class Footer extends React.Component {
	render() {
		return (
			<footer className="footer">
				<div className="columns container is-multiline is-mobile">
					<div className="column is-12-mobile is-3-tablet">
						<div className="logo">
							<img src="/static/img/logo/studycle.png" alt="Studcyle Logo"/>
						</div>
						<div>
							Studycle is a modern colearning space for new generation of learners
							providing personalized learning experience to help everyone reaching their full potentials.
						</div>
					</div>
					<div className="column is-6-mobile is-2-widescreen is-offset-1-widescreen">
						<h6 className="title is-6">Studycle</h6>
						<span><Link to="/our-space/about-us/">About Us</Link></span>
						<span>Careers</span>
						<span>Partnership</span>
						<span>Privacy Policy</span>
						<span><Link to="/our-space/colearning-experience/">Colearning Experience</Link></span>
					</div>
					{/* <div className="column is-3 is-offset-1"> */}
					<div className="column is-6-mobile is-2-widescreen">
						<h6 className="title is-6">Programmes</h6>
						<span><Link to="/programmes/early-years-enrichment/">Early Years Enrichment</Link></span>
						<span><Link to="/programmes/academic-readiness/">Academic Readiness</Link></span>
						<span><Link to="/programmes/career-skills/">Career Skills</Link></span>
						<span><Link to="/courses/">Courses & Workshops</Link></span>
					</div>
					<div className="column is-6-mobile is-2-widescreen">
						<h6 className="title is-6">Study Abroad</h6>
						<span>Schools</span>
						<span>Scholarships</span>
						<span>Study Tour</span>
					</div>
					<div className="column is-6-mobile is-2-widescreen">
						<h6 className="title is-6">Resources</h6>
						<span>Blog</span>
						<span>Learning Materials</span>
						<span>Education Solutions</span>

						<h6 className="title is-6">Find Us</h6>
						{/* <p>Bekasi</p> */}
						<SocialMediaComponent/>
					</div>
				</div>
				<div className="content has-text-centered container">
					<div className="copytext">&copy; {(new Date()).getFullYear()} Studycle. All rights reserved.</div>
				</div>
			</footer>
			);
		}
	}
	
	export default Footer;
	