export const courseData = {
    'steamlabs': {
        id: 1,
        title: 'STEAMLabs',
        sessions: 4,
        content: `
            <p>Early years education is substantial for children’s development. However, many parents enrol their children in kindergarten or early years enrichment classes to learn how to <i>read</i>, <i>write</i> and <i>count</i> merely to fulfil primary schools requirements, without fully understanding their children's interests and needs.</p>
            <p><b>STEAMLabs</b> is our unique early years enrichment programme that provides an integrated cognitive, creative and academic development, designed specifically for pre-schoolers and lower primary school students. The programme is delivered using interactive activities that boost children’s learning interest. Through hands-on projects such as balloon race-car, potato-powered clock, and fruit-print craft, children will be introduced to basic concepts of <i>science</i>, <i>technology</i>, <i>engineering</i>, <i>arts</i> and <i>mathematics</i> (STEAM) in a fun way.</p>
            <p>Through this course, children can grow in all aspects and be ready to walk their learning journey in primary school and beyond.</p>
        `,
        outcomes: [
            'Develop motor, numeracy, literacy and language skills',
            'Think critically and acquire problem-solving skills',
            'Have curiosity to explore the world and think out of the box'
        ],
        audiences: [
            'Children in preparation for primary school',
            'Children who are naturally curious and keen to make sense of the world',
            'Any kids who are excited about learning',
        ],
        cover: '/static/img/gallery/course-steamlabs.jpg',
        cat: 'STEAM',
        url: '/courses/steamlabs',
        ageGroup: [0, 1],
    },
    'having-fun-with-english': {
        id: 2,
        title: 'Having Fun with English',
        sessions: 8,
        content: `
            <p>We deeply believe in the concept of learning English in an enjoyable way with focus on practical everyday use. <b>Having Fun with English</b> will help you learn English through various interactive and hands-on activities to increase your confidence in using the language. This course is designed to allow you enjoy every step in the language learning process. Modules in this course will be delivered based on topics, proficiency levels and students’ needs to allow learners.</p>
        `,
        outcomes: [
            'Understand English comprehensively – reading, writing, speaking and listening',
            'Communicate effectively and appropriately in English',
            'Be comfortable in the English language',
        ],
        audiences: [
            'Complete beginners who want to master English',
            'Learners who are ready to up their English skills',
            'Anyone who wants to build confidence in communication English',
        ],
        cover: '/static/img/gallery/course-fun-english.jpg',
        cat: 'English',
        url: '/courses/having-fun-with-english',
        ageGroup: [0, 1, 2],
    },
    'intro-to-programming': {
        id: 3,
        title: 'Intro to Programming',
        sessions: 4,
        content: `
            <p>Programming skills are getting popular nowadays alongside the increasing demand from industry in this digital era. Our <b>Intro to Programming</b> course will introduce fundamentals of programming to let you be familiar with the subject and software development world. In addition to that, you will also be exposed to various components of computational thinking such as decomposition, pattern recognition, abstraction and algorithms.</p>
            <p>This course covers diverse learning materials and is facilitated by industry experts who will be guiding you not only through the theories and concepts but also hands-on practical implementation. All course modules are using Python, being one of the simplest programming languages for beginners with which you will be able to dive into more advanced fields such as data science, web development, Internet of Things and machine learning easily.</p>
            <p>No programming background is required to join this course. This course will serve as a prerequisite for more advanced programming courses.</p>
        `,
        outcomes: [
            'Develop your own applications',
            'Get a good grasp of fundamental programming knowledge',
            'Start learning more advanced programming subjects confidently',
        ],
        audiences: [
            'Beginners interested in learning programming',
            'Anyone looking for a career in programming fields',
            'Anyone with basic programming skills but lacking on concepts understanding',
        ],
        cover: '/static/img/gallery/course-programming.jpg',
        cat: 'Coding',
        url: '/courses/intro-to-programming',
        ageGroup: [1, 2],
    },
    'mathletics': {
        id: 4,
        title: 'Mathletics',
        sessions: 4,
        content: `
            <p>Our <b>Mathletics</b> is a unique course that is different from other common mathematics tuition classes you often see. This course is aimed to motivate children to have interest in mathematics, thus they no longer fear but grow fond of the subject.</p>
            <p>In this course, mathematics is taught with an approach of activities appealing to children such as using games and through interactive learning materials. Learning methodology as well as the materials in <b>Mathletics</b> are adapted to the needs and abilities of children, so the learning feels more personal and exciting.</p>
            <p>Learning in <b>Mathletics</b> will be facilitated by our qualified tutors who are experts and experienced in the subject. With high interest and concern for development of the children, our tutors will guide the children through the course by not only focusing on achieving better academic grades but also more importantly the profound understanding of diverse mathematical concepts.</p>
        `,
        outcomes: [
            'Better understand concepts of mathematics',
            'Explore and experiment mathematics independently',
            'Enjoy learning mathematics',
        ],
        audiences: [
            'Children who need assistance in understanding core concepts of mathematics',
            'Students looking to improve speed and accuracy in mathematical calculations',
            'Anyone who wants to revise concepts and further knowledge of mathematics',
        ],
        cover: '/static/img/gallery/course-mathletics.jpg',
        cat: 'STEAM',
        url: '/courses/mathletics',
        ageGroup: [1],
    },
    'creative-coding': {
        id: 5,
        title: 'Creative Coding',
        sessions: 4,
        content: `
            <p>Our <b>Creative Coding</b> course is designed specifically for younger learners to let them have the first exposure on and gain experience with programming and computer science concepts. The course is conducted with emphasis on lots of non-screen exploring and playing.</p>
            <p>Children will be guided to express themselves through programming with fun, visual, and creative way such as making digital art project. More confident and interested learners will enjoy the more sophisticated concepts to be applied creatively, encouraging them to experiment and develop independent research and problem solving skills along with their creativity. At the end of each session, children will be coming home inspired by the power of their own imagination.</p>
        `,
        outcomes: [
            'Understand key programming and computer science concepts',
            'Express their idea using programming skills',
            'Work on big ideas by breaking them into small achievable pieces',
        ],
        audiences: [
            'Younger children who want to express themselves creatively',
            'Kids who show interest in technology and digital world',
            'Anyone who wants to gain programming experience without difficult jargons',
        ],
        cover: '/static/img/gallery/course-creative-coding.jpg',
        cat: 'Coding',
        url: '/courses/creative-coding',
        ageGroup: [0, 1],
    },
    'toefl-ielts-preparation': {
        id: 6,
        title: 'TOEFL/IELTS Preparation',
        sessions: 8,
        content: `
            <p>It is never be too early to plan for study abroad. One essential thing to prepare before going abroad for study is English proficiency. Overseas schools or universities always enquire proof that you can communicate well in English in a form of TOEFL or IELTS score. At Studycle, we help you achieve your best score on TOEFL or IELTS so you are one step closer to your dream of studying abroad.</p>
            <p>We understand the significance of getting the best TOEFL or IELTS score. However, we do also believe that learning English still has to be enjoyable. We not only focus on answering practice questions but also emphasize highly on the importance of core foundations of English so that you get an in-depth overall understanding of vocabulary, grammar, tenses and others.</p>
            <p>This <b>TOEFL/IELTS Preparation</b> course also provides a variety of audio and video materials to support you in <i>listening</i> section of the test. Additionally, the tutors will help you develop the logic to build sentences to paragraphs, preparing you to be ready for going through <i>speaking</i> part of the test.</p>
        `,
        outcomes: [
            'Understand foundations of English – reading, speaking, listening, writing',
            'Conquer TOEFL or IELTS test with confidence',
            'Achieve high TOEFL or IELTS score',
        ],
        audiences: [
            'Students who is preparing for overseas study',
            'Scholarship applicants',
            'Anyone in need of fulfilling English proficiency requirement',
        ],
        cover: '/static/img/gallery/course-toefl.jpg',
        cat: 'English',
        url: '/courses/toefl-ielts-preparation',
        ageGroup: [1, 2],
    },
};
