import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet from 'leaflet';

import 'leaflet/dist/leaflet.css';


// Fix Marker Issue - Start
Leaflet.Icon.Default.imagePath = '../../node_modules/leaflet';
delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
// Fix Marker Issue - End

let MAPBOX_ACCESS_TOKEN = '';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic2FtdWVsc2ltb24iLCJhIjoiY2s1bHlxYWlhMGVlMzNrcGZjOXg2Mm9sdCJ9.WU7veWnx8b0b8e1KPf_90Q';
}
else if (process.env.NODE_ENV === 'production') {
    MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic2FtdWVsc2ltb24iLCJhIjoiY2thcGZsMzFzMWQzcjJ0cW04NzFpMXB4dyJ9.1ZyNajNiILsajRIGVI4A5Q'
}

const LocationMap = ({ location, width }) => {
    const [zoom, setZoom] = useState(13);
    const position = [location.lat, location.lng];
    const mapbox = {
        username: 'samuelsimon',
        style_id: 'ckapfw65z0bdh1insmk6n2z35',
        access_token: MAPBOX_ACCESS_TOKEN,
    }

    //let defaultMapStyleUrl = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';
    let mapStyleUrl = `https://api.mapbox.com/styles/v1/${mapbox.username}/`
        + `${mapbox.style_id}/tiles/256/{z}/{x}/{y}@2x?access_token=${mapbox.access_token}`;
    //<a href={`https://maps.google.com/?ll=${location.lat},${location.lng}&z=13`}
    const widthClass = (width !== undefined) ? "is-" + width : "is-12";
    return (
        <div className={`location column has-text-centered ${widthClass}`}>
            <h3 className="title is-4">{ location.name }</h3>
            <Map
                zoom={zoom}
                center={position}
                style={{ height: "350px", width: "100%" }}
                attributionControl={false}
            >
                <TileLayer url={mapStyleUrl} />
                <Marker position={position}>
                    <Popup>
                        Studycle { location.name }<br />

                        <a href={`https://www.google.com/maps/place/${location.lat},${location.lng}/@${location.lat},${location.lng},15z`}
                            target="_blank" rel="noopener noreferrer"
                        >
                            Open in GoogleMap
                        </a>
                    </Popup>
                </Marker>
            </Map>
            <div
                className="address"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(location.address) }}>
            </div>
        </div>
    );
}

export default LocationMap;
