import React from 'react';
import { Redirect } from 'react-router-dom';
import DOMPurify from 'dompurify';

import Layout from 'components/Layout';
import Accordions from 'components/Accordions';
import AgeGroupMark from 'icons/AgeGroupMark';

import { courseData } from 'helpers/courses';


const CourseInfoPanel = (props) => {

//	componentDidUpdate(prevProps) {
//		const currentLocation = this.props.location.pathname.toLowerCase();
//		const prevLocation = prevProps.location.pathname.toLowerCase();
//
//		if (currentLocation !== prevLocation) {
//		    this.fetchPageData();
//		}
//	}

    if (props.course.ageGroup === undefined) return ;

    let ageGroups = []
    for (let i = 0; i < props.course.ageGroup.length; i++) {
        ageGroups.push(<AgeGroupMark key={i} ageGroup={props.course.ageGroup[i]} />)
    }

    return (
        <div className={"card course-info " + (props.side ? 'sticky is-hidden-mobile' : 'is-hidden-tablet')}>
            <ul>
                <li>
                    <span className="icon"><i className="fas fa-clock"></i></span>
                    { props.course.sessions } sessions/month
                </li>
                <li>

                    <span className="icon"><i className="fas fa-user"></i></span>
                    Available for:
                    <ul>
                    {
                        ageGroups &&
                        ageGroups.map((age, idx) => {
                            return <li key={ idx }>{age}</li>
                        })
                    }
                    </ul>
                </li>

                <li>
                    <span className="icon"><i className="fas fa-award"></i></span>
                    Certificate of Completion
                </li>
            </ul>
            {
            /*
            <h4>Course Fee</h4>
            <ul className="price">
                <li>Member: 4 Credits</li>
                <li>Non-member: Rp. 500.000</li>
            </ul>
            */
            }
            <div className="column is-12 has-text-centered">
                <a
                    href={"https://wa.me/628164246780?text=Saya%20mau%20mendaftar%20kelas%20" + props.course.title}
                    target="_blank" rel="noopener noreferrer"
                    className="button is-primary">Register</a>
            </div>
        </div>
    )
}

const SingleCourseView = (props) => {
    const course = courseData[props.match.params.slug]

    if (course === undefined) return <Redirect to='/' />;

    return (
    <Layout pageMeta={{
        title: course.title,
    }}>
        <main className="page course columns is-multiline is-gapless">
            <section className="page-header container">
                <div className="column is-12">
                    <h3 className="subtitle is-4">
                        <span>Course</span>
                    </h3>
                    <h2 className="title is-2">
                        { course.title }
                    </h2>
                </div>
            </section>

            <div className="container columns">
                <div className="column is-8">
                    <figure className="image promote-image">
                        <img src={ course.cover } alt={ course.title } />
                    </figure>

                    <CourseInfoPanel
                        course={ course }
                    />

                    <div className="course-desc">
                        <h3 className="course-desc-title">About This Course</h3>
                        <div
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(course.content) }} />
                        <h4>
                            By the end of this course, learners will be able to:
                        </h4>
                        <ul>
                            {
                                course.outcomes &&
                                course.outcomes.map((outcome, idx) =>
                                    <li key={idx}>{ outcome }</li>
                                )
                            }
                        </ul>
                        {
                        /*
                        <ul>
                            <li>Use the Five Sense framework to give public talks confidently.</li>
                            <li>Plan, structure and effectively deliver short speeches.</li>
                            <li>Harness the power of positive visualisation to prepare you for any public speaking scenario.</li>
                        </ul>
                        */
                        }
                        <h4>
                            This course is for:
                        </h4>
                        <ul>
                            {
                                course.audiences &&
                                course.audiences.map((audience, idx) =>
                                    <li key={idx}>{ audience }</li>
                                )
                            }
                        </ul>
                        {
                        /*
                        <ul>
                            <li>Business leaders looking for an edge in their next presentation.</li>
                            <li>Entrepreneurs and creatives who want to be able to tell a strong brand story.</li>
                            <li>Anyone who wants to build confidence in their speaking skills.</li>
                        </ul>
                        */
                        }

                        <p></p>
                        <Accordions
                            //selectedItem={0}
                            data={[
                                {
                                    title: 'Course Content',
                                    content: '<p class="quote">Contact us for more details.</p>',
                                },
                                {
                                    title: 'Schedules',
                                    content: '<p class="quote">Contact us for more details.</p>',
                                },
                            ]}
                        />

                        <table>
                        <thead>
                            <tr>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
                <div className="column course-info-container">
                    <CourseInfoPanel
                        course={ course }
                        side={ true } />
                </div>
            </div>

        </main>
    </Layout>)
}


export default SingleCourseView;
