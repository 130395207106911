import React, {Component} from "react";
import {connect} from "react-redux";

//import {auth} from "../redux/actions";

class LoginForm extends Component {
  state = {
    username: "",
    password: "",

    errors: [],
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ errors: [] })
    //this.props.login(this.state.username, this.state.password);
    this.setState({
        errors: ["Invalid username/password"]
    })
  }

  render() {
    return (
        <form onSubmit={this.onSubmit} className="box">
            <h3 className="title is-4">Login to your account</h3>
            {
                this.state.errors.length > 0 && (
                <ul className="errors">
                    {
                        this.state.errors.map((error, idx) => (
                            <li key={idx}>{error}</li>
                        ))
                    }
                </ul>
            )
            }
            <div className="field">
                <label className="label">Username</label>
                <div className="control has-icons-left">
                    <input name="username" placeholder="e.g. user123" className="input" 
                    onChange={e => this.setState({username: e.target.value})} />
                    <span className="icon is-small is-left">
                    <i className="fa fa-user"></i>
                    </span>
                </div>
            </div>
            <div className="field">
                <label className="label">Password</label>
                <div className="control has-icons-left">
                    <input name="password" type="password" placeholder="*******" className="input"
                    onChange={e => this.setState({password: e.target.value})} />
                    <span className="icon is-small is-left">
                    <i className="fa fa-lock"></i>
                    </span>
                </div>
            </div>
            <div className="field is-grouped is-grouped-centered">
                <input type="submit" value="Login" className="button is-primary"/>
            </div>
        </form>
    )
  }
}

// const mapStateToProps = state => {
//   let errors = [];
//   if (state.auth.errors) {
//     errors = Object.keys(state.auth.errors).map(field => {
//       return {field, message: state.auth.errors[field]};
//     });
//   }
//   return {
//     errors,
//     isAuthenticated: state.auth.isAuthenticated
//   };
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     login: (username, password) => {
//       return dispatch(auth.login(username, password));
//     }
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
export default LoginForm;