import React, { useState } from "react";

import Accordion from "./Accordion";

function Accordions(props) {
    const initialSelectedItem = parseInt(props.selectedItem) >= 0 ? parseInt(props.selectedItem) : -1;
    const [selectedItem, setSelectedItem] = useState(initialSelectedItem);

    function toggleAccordion(e) {
        const newSelectedItem = parseInt(e.currentTarget.dataset.id)

        if (newSelectedItem !== selectedItem)
            setSelectedItem(parseInt(e.currentTarget.dataset.id))
        else
            setSelectedItem(-1)
    }

    return (
        <div className="accordions">
            {
                props.data.map((item, idx) => (
                    <Accordion
                        key={idx}
                        id={idx}
                        setActive={idx === selectedItem ? "active" : ""}
                        toggle={toggleAccordion}
                        title={item.title}
                        content={item.content}
                    />
                ))
            }
        </div>
    );
}

export default Accordions;