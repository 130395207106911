import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import SectionWrapper from './Wrapper';


const Intro = ({ title, children }) => {
    const { t } = useTranslation('pages');
    /*
    const dummy_data_en = [
        {
            title: 'Flexible Learning',
            content: 'We believe that learning should not be limited by location, time, pace and mode. With all-access pass to the skills, resources and support to get ahead, you will be able to future-proof yourself.',
            icon: 'clock',
            color: '#2DB798',
        },
        {
            title: 'Activity Based',
            content: 'Through our activity and project-based learning, you will have hands-on opportunities to develop and improve your skills more effectively.',
            icon: 'clipboard-list',
            color: '#D04561',
        },
        {
            title: 'Supportive Community',
            content: 'Everyone shares a safe space where we are able to express our abilities and grow together to our full potential.',
            icon: 'users',
            color: '#285F9F',
        },
        {
            title: 'Expert Educators',
            content: 'Our educators are the heart of Studycle. They provide guidance through combination of theoretical knowledge and practical experience.',
            icon: 'user-graduate',
            color: '#FCC033',
        },
    ];
    */

    const dummy_data_id = [
        {
            title: 'Flexible Learning',
            content: 'We believe that learning should not be limited by location, time, pace and mode. With all-access pass to the skills, resources and support to get ahead, you will be able to future-proof yourself.',
            icon: 'clock',
            color: '#2DB798',
        },
        {
            title: 'Activity Based',
            content: 'Through our activity and project-based learning, you will have hands-on opportunities to develop and improve your skills more effectively.',
            icon: 'clipboard-list',
            color: '#D04561',
        },
        {
            title: 'Supportive Community',
            content: 'Everyone shares a safe space where we are able to express our abilities and grow together to our full potential.',
            icon: 'users',
            color: '#285F9F',
        },
        {
            title: 'Expert Educators',
            content: 'Our educators are the heart of Studycle. They provide guidance through combination of theoretical knowledge and practical experience.',
            icon: 'user-graduate',
            color: '#FCC033',
        },
    ];

    const dummy_data = dummy_data_id
    return (
        <SectionWrapper>
            <div className="column is-12-mobile is-5-tablet is-6-desktop">
                <h2 className="title is-size-4-mobile is-size-2-desktop">
                    <Trans i18nKey="pages:home.features.title">
                        Be a member,<br />Prepare for your future
                    </Trans>
                </h2>
                <span className="subtitle is-size-6-mobile is-size-5-tablet">
                <Trans i18nKey="pages:home.features.desc">
                    Studycle brings new experience to education. <br />
                    Our affordable membership provides you or your children with
                    everything needed to navigate the future.
                </Trans>
                </span>
                <div style={{
                    borderRadius: "8px",
                    marginTop: "2.25rem",
                    overflow: "hidden",
                    width: "90%",
                }}>
                    <figure className="image">
                        <img src="/static/img/gallery/studycle-cikarang.jpg" alt=""></img>
                    </figure>
                </div>

            </div>
            <div className="column columns is-mobile is-multiline">
                { dummy_data.map((data, idx) => {
                return (
                    <div className="column is-12-mobile is-6-tablet" key={idx}>
                        <span className="icon is-large"  style={{
                            color: data.color,
                            borderRadius: "4px",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                            background: data.color+"2B",
                        }}>
                            <i className={"fas fa-2x fa-" + data.icon } style={{ opacity: 1 }} />
                        </span>
                        <h3 className="title is-5">{ data.title }</h3>
                        <p className="subtitle is-6">{ data.content }</p>
                    </div>
                )
                }) }
                <div className="colume is-12" style={{ marginTop: "1rem", padding: "0.75rem" }}>
                    <Link
                        className="button is-primary"
                        to="/register"
                    >
                        { t('', 'Register Now') }
                    </Link>
                </div>
            </div>
        </SectionWrapper>
    );
};

export default Intro;