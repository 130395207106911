import React from 'react';

import Layout from 'components/Layout';

const AboutUsView = () => (
    <Layout pageMeta={{
        title: "Our Story",
    }}>
        <main className="page columns is-multiline is-gapless">
            <div className="column is-12 is-size-5 has-text-weight-normal">
                <section className="section page-content container">
                    <div className="columns is-vcentered">
                        <figure className="column image">
                            <img src="/static/img/pages/about-us.jpg" alt="About Us" />
                        </figure>

                        <div className="column is-6">
                            <h3 className="subtitle is-4">
                                <span>Colearning Space</span>
                            </h3>
                            <h2 className="title is-2">
                                <span>About Us</span>
                            </h2>
                            <p className="quote is-size-4">
                            Competition makes us faster, collaboration makes us better
                            </p>
                            <p>
                            We believe that most traditional education is boring, difficult and burdensome for a lot of students.
                            Driven by our passion to fill the gaps in education systems, we create a fun, interactive and
                            personalized platform for everyone to start enjoying their learning journey
                            and unleash their full potential.
                            </p>
                            <p>
                            Our aim is to enable children and young adults to be confident,
                            independent and self-directed learners while also encourage them to be
                            willing to contribute to society.
                            </p>
                        </div>
                    </div>
                </section>

                <blockquote className="blockquote columns is-yellow is-gapless">
                    <div className="column is-12">
                        <i className="fas fa-2x fa-quote-left is-size-5" />
                    </div>
                    <div className="column is-10 is-size-3">
                        Where learning is fun, enjoyable and accessible for everyone
                    </div>
                </blockquote>

                <section className="page-header container has-text-centered">
                    <div className="column is-12">
                        <h2 className="title is-2">
                            What <span>We Offer</span>
                        </h2>
                    </div>
                </section>

                <div className="column is-12">
                    <section className="page-content container">
                        <div className="our-services columns is-centered is-multiline">
                            <div className="column is-4 columns is-vcentered">
                                <div className="column is-3">
                                    <span className="icon"><i className="fas fa-users" /></span>
                                </div>
                                <div className="column desc">
                                    <p className="title">Collaborative Learning</p>
                                </div>
                            </div>
                            <div className="column is-4 columns is-vcentered">
                                <div className="column is-3">
                                    <span className="icon"><i className="far fa-paper-plane" /></span>
                                </div>
                                <div className="column desc">
                                    <p className="title">Activity-based Courses</p>
                                </div>
                            </div>
                            <div className="column is-4 columns is-vcentered">
                                <div className="column is-3">
                                    <span className="icon"><i className="far fa-play-circle" /></span>
                                </div>
                                <div className="column desc">
                                    <p className="title">Interactive Resources</p>
                                </div>
                            </div>
                            <div className="column is-4 columns is-vcentered">
                                <div className="column is-3">
                                    <span className="icon"><i className="fas fa-graduation-cap" /></span>
                                </div>
                                <div className="column desc">
                                    <p className="title">Education Consultation</p>
                                </div>
                            </div>
                            <div className="column is-4 columns is-vcentered">
                                <div className="column is-3">
                                    <span className="icon"><i className="fas fa-dice-d6" /></span>
                                </div>
                                <div className="column desc">
                                    <p className="title">Enterprise Learning Solutions</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    </Layout>
)


export default AboutUsView;
	