import React from 'react';

import Layout from './Layout';


class PageNotFoundComponent extends React.Component {
	render() {
		return (
			<Layout pageMeta={{
                title: 'Page Not Found',
            }}>
				<main style={{ height: "50vh" }}>
					<section className="section container columns is-centered">
						<div
							className="column is-8 is-size-5 has-text-centered"
							style={{ marginTop: "2rem" }}>
							<div className="title is-2 has-text-weight-normal">
								Our awesome resources are <strong className="has-text-weight-bold">Coming Soon</strong>
							</div>
							<p style={{ marginTop: "3rem" }}>
								Meanwhile feel free to browse through other information in our website.
							</p>
						</div>
					</section>
				</main>
			</Layout>
		)
	}
}

export default PageNotFoundComponent;
